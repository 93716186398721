export { default, modalActions, modalSlice } from "./modalSlice";

export enum MODAL {
	HANDLE_STATUS = "HANDLE_STATUS",
	CHANGE_PASSWORD = "CHANGE_PASSWORD",
	CONFIRM_TRANSFER = "CONFIRM_TRANSFER",
	CONFIRMATION_FORM = "CONFIRMATION_FORM",
}

export type ModalType = "" | keyof typeof MODAL;

export interface OpenModalState {
	data: any;
	width: any;
	type: ModalType;
	loading?: boolean;
}

export type ModalState = OpenModalState & {
	open: boolean;
};
