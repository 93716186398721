import { lazy } from "react";

const Login = lazy(() => import("pages/Login"));
const PasswordOTP = lazy(() => import("pages/PasswordOTP"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));

const Profile = lazy(() => import("pages/Profile"));
const Dashboard = lazy(() => import("pages/Dashboard"));

const Bookings = lazy(() => import("pages/Bookings"));
const BookingDetails = lazy(() => import("pages/BookingDetails"));

const Cars = lazy(() => import("pages/Cars"));
const AddCar = lazy(() => import("pages/AddCar"));
const CarDetails = lazy(() => import("pages/CarDetails"));

const Extras = lazy(() => import("pages/Extras"));
const AddExtra = lazy(() => import("pages/AddExtra"));
const ExtrasDetails = lazy(() => import("pages/ExtrasDetails"));

const SupportTickets = lazy(() => import("pages/SupportTickets"));
const AddSupportTicket = lazy(() => import("pages/AddSupportTicket"));
const SupportTicketDetails = lazy(() => import("pages/SupportTicketDetails"));

const CarPrice = lazy(() => import("pages/CarPrice"));
const AddCarPrice = lazy(() => import("pages/AddCarPrice"));
const CarPriceDetails = lazy(() => import("pages/CarPriceDetails"));

const Branches = lazy(() => import("pages/Branches"));
const AddBranch = lazy(() => import("pages/AddBranch"));
const BranchDetails = lazy(() => import("pages/BranchDetails"));

export { default } from "./AppRoutes";

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export const public_routes: IRoute[] = [
  { path: "/", element: <Login /> },
  { path: "/password-otp", element: <PasswordOTP /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
];

export const private_routes: IRoute[] = [
  { path: "/profile", element: <Profile /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/booking", element: <Bookings type={"confirm"} /> },

  { path: "/ongoing-bookings", element: <Bookings type={"inprogress"} /> },
  { path: "/past-bookings", element: <Bookings type="bookings" /> },
  { path: "/booking-details/:id", element: <BookingDetails /> },

  { path: "/cars", element: <Cars /> },
  { path: "/add-supplier-car", element: <AddCar /> },
  { path: "/update-supplier-car/:id", element: <AddCar /> },
  { path: "/car-details/:id", element: <CarDetails /> },

  { path: "/extras", element: <Extras /> },
  { path: "/add-supplier-extra", element: <AddExtra /> },
  { path: "/update-supplier-extra/:id", element: <AddExtra /> },
  { path: "/extra-details/:id", element: <ExtrasDetails /> },

  { path: "/car-prices", element: <CarPrice /> },
  { path: "/add-car-price", element: <AddCarPrice /> },
  { path: "/update-car-price/:id", element: <AddCarPrice /> },
  { path: "/car-price-details/:id", element: <CarPriceDetails /> },

  { path: "/branches", element: <Branches /> },
  { path: "/add-branch", element: <AddBranch /> },
  { path: "/update-branch/:id", element: <AddBranch /> },
  { path: "/branch-details/:id", element: <BranchDetails /> },

  { path: "/support", element: <SupportTickets /> },
  { path: "/add-ticket", element: <AddSupportTicket /> },
  { path: "/ticket-details/:id", element: <SupportTicketDetails /> },
];
