import "./App.css";

import { store } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import AppRoutes from "routes/AppRoutes";
import Toaster from "components/atoms/Toaster";

import createCache from "@emotion/cache";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { CacheProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/lab";
import dateAdapter from "@mui/lab/AdapterDateFns";
// import { ScopedCssBaseline } from "@mui/material";
import AppModal from "components/templates/AppModal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ErrorBoundary from "components/atoms/ErrorBoundary";
import RegisterAppDispatch from "components/atoms/RegisterAppDispatch";
import { SidebarLayout } from "components/templates/Sidebar/SidebarLayout";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";

export default function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === "ar") {
      document.body.style.direction = "rtl";
      document.documentElement.lang = "ar";
      document.documentElement.dir = "rtl";
    } else {
      document.body.style.direction = "ltr";
      document.documentElement.lang = "en";
      document.documentElement.dir = "ltr";
    }
  }, [i18n.language]);

  const cache = createCache({ key: "css", prepend: true });

  const cacheRtl = createCache({
    key: "muirtl",
    //@ts-ignore
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const theme = createTheme({
    status: {
      success: {
        color: "rgba(84, 214, 44, 0.16)",
        textContrast: "rgb(34, 154, 22)",
      },
      warning: {
        color: "rgba(255, 193, 7, 0.16)",
        textContrast: "rgb(183, 129, 3)",
      },
    },
    palette: {
      primary: {
        light: "#4b525a",
        main: "#ed1c24",
        dark: "#050608",
        // contrastText: "rgb(2, 136, 209)",
      },
      secondary: {
        light: "#fffadd",
        main: "#ffcd00",
        dark: "#e7c400",
        // contrastText: "rgb(2, 136, 209)",
      },
      success: {
        main: "rgb(34, 154, 22)",
        light: "rgba(84, 214, 44, 0.16)",
        dark: "#1b5e20",
        // contrastText: "rgb(34, 154, 22)",
      },
      warning: {
        main: "rgb(183, 129, 3)",
        light: "rgba(255, 193, 7, 0.16)",
        dark: "#e65100",
        // contrastText: "rgb(183, 129, 3)"
      },
      error: {
        main: "rgb(183, 33, 54)",
        light: "rgba(255, 72, 66, 0.16)",
        dark: "#c62828",
        // contrastText: "rgb(183, 33, 54)",
      },
      info: {
        main: "rgb(2, 136, 209)",
        light: "rgb(2, 136, 209, 0.16)",
        dark: "#01579b",
        // contrastText: "rgb(2, 136, 209)",
      },
    },
    direction: i18n.language === "ar" ? "rtl" : "ltr",
    typography: {
      fontFamily: '"Noto Sans", sans-serif',
    },
  });

  return (
    <ErrorBoundary>
      <SnackbarProvider
        maxSnack={5}
        hideIconVariant
        preventDuplicate
        anchorOrigin={{
          vertical: "bottom",
          horizontal: i18n.language === "ar" ? "left" : "right",
        }}
        iconVariant={{
          success: "✅",
          error: "✖️",
          warning: "⚠️",
          info: "ℹ️",
        }}
      >
        <Toaster />
        <ErrorBoundary>
          <CacheProvider value={i18n.language === "ar" ? cacheRtl : cache}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={dateAdapter}>
                <CssBaseline />
                {/* <ScopedCssBaseline> */}
                <Provider store={store}>
                  <RegisterAppDispatch />
                  <BrowserRouter>
                    {/* <ErrorBoundary>
											<Header />
										</ErrorBoundary> */}
                    <SidebarLayout key={i18n.language}>
                      <ErrorBoundary>
                        <AppModal />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <AppRoutes />
                      </ErrorBoundary>
                    </SidebarLayout>
                  </BrowserRouter>
                </Provider>
                {/* </ScopedCssBaseline> */}
              </LocalizationProvider>
            </ThemeProvider>
          </CacheProvider>
        </ErrorBoundary>
      </SnackbarProvider>
    </ErrorBoundary>
  );
}
