import { config } from "config";
import { SetExtrasPayload, ExtrasState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: ExtrasState = {
  extras: [],
  extra: null,
  loading: true,
  current_filters: {},
  extraOptions: [],
  filters: { page: 1, page_size: default_page_size },
  refresh: 0,
  refreshLoader: false,
  totalCount: 0,
};

export const extraSlice = createSlice({
  name: "extra",
  initialState,
  reducers: {
    clear: (state) => {
      state.extraOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addExtra: (state, action) => {
      state.extras.unshift(action.payload);
    },
    updateExtra: (state, action) => {
      const { id, extra } = action.payload;
      state.extras.every(({ _id }, i) => {
        if (id === _id) {
          state.extras[i] = extra;
          return false;
        }
        return true;
      });
    },
    deleteExtraById: (state, action) => {
      const id = action.payload;
      state.extras.every(({ _id }, i) => {
        if (id === _id) {
          state.totalCount -= 1;
          state.extras.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setExtra: (state, action) => {
      state.extra = action.payload;
    },
    setTotalCount: (state, action: PayloadAction<any>) => {
      const { totalCount } = action.payload;
      state.totalCount = totalCount;
    },
    setExtras: (state, action: PayloadAction<SetExtrasPayload>) => {
      const { totalCount, extras } = action.payload;
      let options: SelectOption[] = [];

      extras.forEach(({ _id, name }: any) => {
        const option = { value: _id, label: `${name}` };
        options.push(option);
      });

      state.totalCount = totalCount;
      state.extras = extras;
      state.extraOptions = options;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filters.page = action.payload;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const extraReducer = extraSlice.reducer;

export const extraActions = extraSlice.actions;
export default extraReducer;
