import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./slices/auth";
import modalReducer from "./slices/modal";
import loaderReducer from "./slices/loader";
import bookingReducer from "./slices/booking";
import { navigationReducer } from "./slices/sidebar/navigationSlice";
import formLoaderReducer from "./slices/formLoader/formLoaderSlice";
import userReducer from "./slices/user/userSlice";
import priceReducer from "./slices/price/priceSlice";
import supplierReducer from "./slices/supplier/supplierSlice";
import branchReducer from "./slices/branch/branchSlice";
import carCategoryReducer from "./slices/carCategory/carCategorySlice";
import carModelReducer from "./slices/carModel/carModelSlice";
import carReducer from "./slices/car/carSlice";
import carPriceReducer from "./slices/carPrice/carPriceSlice";
import carSpecReducer from "./slices/carSpec/carSpecSlice";
import extraReducer from "./slices/extras/extraSlice";
import extraModelReducer from "./slices/extraModel/extraModelSlice";
import supportTicketReducer from "./slices/supportTicket";
import addChartBookingCountReducer from "./slices/dashboardChart";

const appReducer = combineReducers({
  form: formReducer,

  auth: authReducer,
  user: userReducer,
  supplier: supplierReducer,
  branch: branchReducer,
  extra: extraReducer,
  car: carReducer,
  extraModel: extraModelReducer,
  carPrice: carPriceReducer,
  carCategory: carCategoryReducer,
  carModel: carModelReducer,
  carSpec: carSpecReducer,
  price: priceReducer,
  modal: modalReducer,
  loader: loaderReducer,
  booking: bookingReducer,
  chartBooking: addChartBookingCountReducer,
  formLoader: formLoaderReducer,
  navigation: navigationReducer,
  supportTicket: supportTicketReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") state = {};
  return appReducer(state, action);
};

export default rootReducer;
