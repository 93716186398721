import { lazy, useMemo } from "react";
import {
  AirportShuttleOutlined,
  FactCheckOutlined,
  AccountTreeOutlined,
  TimeToLeaveOutlined,
  SupportAgent,
  AddBoxOutlined,
  Support,
} from "@mui/icons-material";
import UpcomingOutlinedIcon from "@mui/icons-material/UpcomingOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { NavigationItemType } from "./components/navigation-item/types";
import { useTranslation } from "react-i18next";
// import BookingsTabs from "pages/Bookings/BookingsTabs";
const BookingsTabs = lazy(() => import("pages/Bookings/BookingsTabs"));

export const useNavigationItems = (): NavigationItemType[] => {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        header: "",
      },
      {
        path: "/dashboard",
        label: t("dashboard"),
        icon: (props: any) => <DashboardOutlinedIcon {...props} />,
      },
      {
        path: "/booking",
        label: t("bookings"),
        icon: (props: any) => <SupportAgent {...props} />,
        items: [
          {
            label: t("upcoming"),
            icon: (props: any) => <UpcomingOutlinedIcon {...props} />,
            element: <BookingsTabs />,
          },
          {
            label: t("on-going"),
            icon: (props: any) => <AirportShuttleOutlined {...props} />,
            element: <BookingsTabs />,
          },
          {
            label: t("past"),
            icon: (props: any) => <FactCheckOutlined {...props} />,
            element: <BookingsTabs />,
          },
        ],
      },
      {
        path: "/cars",
        label: t("cars"),
        icon: (props: any) => <TimeToLeaveOutlined {...props} />,
      },
      {
        path: "/extras",
        label: t("extras"),
        icon: (props: any) => <AddBoxOutlined {...props} />,
      },
      // {
      //   path: "/car-prices",
      //   label: "Pricing",
      //   icon: (props: any) => <AccountBalanceWalletOutlinedIcon {...props} />,
      // },
      {
        path: "/branches",
        label: t("branches"),
        icon: (props: any) => <AccountTreeOutlined {...props} />,
      },
      {
        path: "/support",
        label: t("support"),
        icon: (props: any) => <Support {...props} />,
      },
    ],
    []
  );
};
